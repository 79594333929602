<template>
  <v-pagination
    outlined
    dark
    style="background: #e5e5e5;"
    color="#f5f5f5"
    total-visible="7"
    v-model="get_produtocategorias_filtros.page"
    :length="get_produtocategorias_filtros.totalPages"
    @input="setPage($event)"
  ></v-pagination>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_produtocategorias_filtros"])
  },
  methods: {
    ...mapActions(["listar_produtocategorias"]),
    setPage(page) {
      this.get_produtocategorias_filtros.page = page;
      this.listar_produtocategorias();
    }
  }
};
</script>
